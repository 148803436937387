@import "../../tribe.scss";

// .MainScreenDiv{
//     background-color: #20201F;
//     background-repeat: no-repeat;
//     background: url(../../img/bkg.svg);
//     margin: 0;
//     height:100vh;

// }   

//Mobile
@media (max-width: 767px) {

    .MenuContainer{

        visibility:collapse;
        display: none;
        
    }
    .container-into-main {
        padding: 20px;
       
    }
    .HeroImageDiv{
        visibility:collapse;
        display: none;
    }
    .logoDiv{
        margin-bottom: 25px;
        margin-top: 100px;
        max-height: 53px;
    }
    .into-tagline{
        font-family: "RNSSanz-ExtraBold";
        color: #FAF115;
        font-weight: 900;
        font-size: 2.5rem;
        line-height: 1.09em;
        margin-bottom: 2rem;
        min-width: 350px;
    }
    .into-subtitle{
        font-family: 'RNSSanz-Medium';
        // color: #383838;
        text-align: 'left';
        margin-bottom: 0.5em;
        color: #fff;
        font-weight: 600;
        font-size: 1.4em;
        line-height: 1.4em;
    }

    .OutlineButton{
        border: 2px solid #1990ff;
        padding: 0.5rem;
        min-width: 330px;
        border-radius: 4px;
        background: transparent;
        color: white;
        margin-top: 1rem;
        font-size: 0.9rem;
    }
    
    .YellowSolidButton{
        padding: 0.5rem;
        min-width: 330px;
        border-radius: 4px;
        background: #FAF115;
        border: 2px solid #faf115;
        color: #000;
        margin-top: 1rem;
        font-size: 0.9rem;
    }

   }


// Desktop
   @media (min-width: 768px) {

    .MenuContainer{
        visibility: visible;
        display: flex;
        width: 100%;
        flex-direction: row;
        padding-left: 2rem;
        padding-top: 1rem;
    }
        
    
    .MenuContainer img {
        padding-top: 1rem;
        height: 50px;
        padding-left: 20px;

    }

    .MenuButtons {
        display: flex;
    }
    .secondaryButtons{
       display: flex;
        flex-direction: row;
        
    }
    .container-into-main {
        flex-direction: row;
        display: flex;
        width: 1024px;
        margin: auto;
        // padding: 20px; 
    }
    .HeroContentDiv{
        width: 415px;
        height: 100vh;
        // max-width: 350px;
    }
    .HeroImageDiv{
    visibility: visible;
    display: block;
    // width: 50%;
    }
    .HeroImage{
        width: 95%;
        margin-bottom: 25px;
        margin-top: 2rem;
        padding-right: 1rem;
        padding-left: 1rem;
        // max-width: 620px;
        min-width: 450px;
    }
    
    .logoDiv{
        visibility: collapse;
        display: none;
    }
    .into-tagline{
        font-family: "RNSSanz-ExtraBold";
        color: #FAF115;
        font-weight: 900;
        font-size: 2.5rem;
        line-height: 1.09em;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    .into-subtitle{
        font-family: "RNSSanz-Medium";
        text-align: "left";
        margin-bottom: 0.5rem;
        color: #fff;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.4em;
    }

    .OutlineButton{
        border: 2px solid #1990ff;
        padding: 0.5rem;
        min-width: 145px;
        border-radius: 4px;
        background: transparent;
        color: white;
        margin: 1rem;
        font-size: 0.9rem;
    }
    
    .YellowSolidButton{
        padding: 0.5rem;
        min-width: 145px;
        border-radius: 4px;
        background: #FAF115;
        border: 2px solid #faf115;
        color: #000;
        margin: 1rem;
        font-size: 0.9rem;
    }
    
   
}


.container-into {
    padding: 20px;  
}


.group-button-into{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'RNSSanz-Black'
}

.button-into {
    margin-top: 20px;
    border-radius: 4px;
    width: 330px;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
    // border-width: 0.1rem;
    // border-color: rgb(9 132 255);

;
}

.AppDiv{
    margin: auto;
    max-width: 960px;
    padding: 0px;
}

