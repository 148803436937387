.MessageForm { text-align: left; padding: 15px; font-size: 1.2rem;}
.labelheader{
    /* font-style: normal; */
    /* font-weight: 400; */
    /* font-size: 16px; */
    line-height: 22px;
    padding-bottom: 12px;
    display: block;
    /* identical to box height, or 138% */
    color: #3D6B96;}

    .librarybutton{
justify-content: center; 
height: 50px;
 align-items: center; 
/* gap: 10px; */
color: #fff;
font-size: 18px;
width:100%;
margin-top: 12px;
margin-bottom: 16px;
background: #096DD9;
border: 1px solid #096DD9;
border-radius: 2px;


    }

.datetimepickerdiv{
    display: flex;
    flex-direction: row;
}
.datetimepickerdiv div { width: 90%;}
.datetimepickerdiv .item {
    margin: 10px;
}