
@import "../../tribe.scss";


.LoginTagline{

    font-family: 'RNSSanz-ExtraBold';
    // color: #383838;
    // text-align: 'left';
    color: rgb(9 132 255);
    font-weight: 900;
    font-size: 2.4rem;
    line-height: 2.4rem;
    margin-bottom: 0.5em;
    // font-weight: "bold";

}

.LoginSubtitle{
    font-family: 'RNSSanz-Medium';
    // color: #383838;
    text-align: 'left';
    margin-bottom: 0.5em;
    color: rgb(54 54 54 / 85%);
    font-weight: 600;
    font-size: 1.4em;
    line-height: 1.4em;
}

.LoginButton {
    margin-top: 20px;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
;
}


.group-button-into{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'RNSSanz-Black'
}

