.infinite-container {
    overflow: auto;
    height: 400px;
}

.align-custom {
    margin-top: 10%;
}

.custom-card {
    border-radius: 20px;
    -webkit-box-shadow: -1px 3px 24px -1px rgba(181, 181, 181, 1);
    -moz-box-shadow: -1px 3px 24px -1px rgba(181, 181, 181, 1);
    box-shadow: -1px 3px 24px -1px rgba(181, 181, 181, 1);
}

.custom-input {
    border-radius: 10px !important;
    height: 40px;
}

.ant-select-selection {
    border-radius: 20px !important;
}

.ant-select .ant-select-selector {
    border-radius: 10px !important;
    height: 40px !important;
    text-align: left;
}

.custom-button {
    min-height: 40px;
    border-radius: 20px;
    min-width: 100px;
    border: 1px solid #5468ff;
    font-weight: 500;
}

.custom-button:hover {
    border: 1px solid rgb(0, 122, 255);
    background: white;
    color: rgb(0, 122, 255);
    font-weight: 500;
}

@media(max-width: 576px) { 
    .ant-picker-datetime-panel { 
      flex-direction: column;
      bottom: 100px !important;
      height: 200px;
      overflow-y: scroll;
    } 
  }


