.preview-holder{
    padding: 18px;
    background: #e7e7e7;
border-radius: 24px 24px 24px 24px;
}

.preview-bubble-blue {

margin-top: 20px;
padding: 18px;
min-width: 300px;
max-width: 320px;
min-height: 150px;

background: #439BEC;
border-radius: 32px 32px 32px 4px;

font-family: 'Helvetica';
font-style: normal;
font-weight: 400;
font-size: 18.1533px;
line-height: 23px;
color: #FFFFFF;
}

.preview-bubble-grey {
    margin-top: 15px;
    margin-left: 100px;
    padding: 18px;
    min-width: 300px;
    max-width: 320px;
    min-height: 100px;
    
    background: #F1EFEF;
    border-radius: 32px 32px 4px 32px;
    border: 1px solid #abacac;
    
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 18.1533px;
    line-height: 23px;
    
    color: #4b4242;
    }
.preview-person-name {
font-family: 'Helvetica';
font-style: normal;
font-weight: 400;
font-size: 12.5677px;
line-height: 14px;
text-align: right;
color: #4B4242;
padding: 12px;
}
.preview-merchant-name {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 12.5677px;
    line-height: 14px;
    text-align: left;
    color: #4B4242;
    padding: 12px;
    }
