.HeadlineWithSubtitle {}
div{ }
.Headline{

font-family: 'futura';
font-style: normal;
font-weight: 500;
font-size: 24px;
/* line-height: 22px; */
/* identical to box height, or 92% */
color: #3D6B96;

}
.Subtitle {
font-family: 'futura';
font-style: normal;
font-weight: 500;
font-size: 16px;
/* line-height: 22px; */
/* identical to box height, or 138% */


color: #4B4242;

}