body {
    font-family: 'RNSSanz-Medium';
}
.HashtagDetails {
    
    font-family: 'RNSSanz-Medium';
    font-size: 1.3rem;
    line-height: 1.3rem;
    border: 0.2rem dashed #000000;
    padding: 0.8rem;
    margin: 0.4rem;
    margin-top: 20px;


}
.SuccessDiv{
    margin: 20px;
    padding: 16px;
    border-radius: 10px;
    background-color: #fff;
}