.LandingPageContent {}
.lp-tagline{
font-family: "futura";
width: 412px;
height: 74px;
font-style: normal;
font-weight: 700;
font-size: 31.52px;
line-height: 35px;
text-align: left;
color: #3D6B96;
margin-bottom: 1.2rem;
 
}
.lp-subtitle{
font-family: "futura";
width: 408px;
height: 50px;
text-align: left;
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 23px;
color: #4B4242;
margin-bottom: 1.2rem;
}
.lp-hero-image {
width: 403.96px;
height: 197px;
}