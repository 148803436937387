
.LogoImage{
    width: 63px;
    height:63px;
    
    /* margin-bottom: 25px;
    margin-top: 2rem;
    padding-right: 1rem;
    padding-left: 1rem; */
    max-width: 63px;
    min-width: 63px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.w-menu-container{
    width: 100%;
    background-color: white;
    text-align: center;
}

.LandingPageMenu {}