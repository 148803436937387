@import '~antd/dist/antd.css';


body{
  background-color: #FFF;
  /* background-color: #FFFDF3; */
} 

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.Title {

  font-family: 'RNSSanz-ExtraBold';
  color: rgb(9 132 255);
  font-weight: 900;
  font-size: 2.0rem;
  line-height: 1.9rem;
  margin-bottom: 0.5em;


}
.Title_White{ 
  font-family: 'RNSSanz-ExtraBold';
  color: #fff;
  font-weight: 900;
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin-bottom: 0.5em;
}
.TitleBold {
  font-family: 'RNSSanz-Medium';
  margin-bottom: 0.5em;
  color: rgb(54 54 54 / 85%);
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1.2em;
  text-align: left;
}
.Subtitle{
  font-family: 'RNSSanz-Medium';
  text-align: 'left';
  margin-bottom: 0.5em;
  color: rgb(54 54 54 / 85%);
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1.2em;
}

.SubtitleBold{
  font-family: 'RNSSanz-Medium';
  margin-bottom: 0.5em;
  color: rgb(54 54 54 / 85%);
  font-weight: 800;
  font-size: 1.8em;
  line-height: 1.4em;
  text-align: left;
}

.Subtitle_White{
  font-family: 'RNSSanz-Medium';
  text-align: 'left';
  margin-bottom: 0.5em;
  color: #fff;
  font-weight: 600;
  font-size: 1.4em;
  line-height: 1.4em;
}

.NormalText{
  font-family: 'RNSSanz-Medium';
  margin-bottom: 0.5em;
  color: rgb(54 54 54 / 85%);
  font-size: 1rem;
  text-align: left;

}


/* .SignupButton {
  margin-top: 20px;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
;
} */


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

