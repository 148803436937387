@font-face {
    font-family:RNSSanz-Black;
    src: url('./fonts/RNSSanz-Black.otf');
}

@font-face {
    font-family:RNSSanz-Bold;
    src: url('./fonts/RNSSanz-Bold.otf');
}
@font-face {
    font-family:RNSSanz-ExtraBold;
    src: url('./fonts/RNSSanz-ExtraBold.otf');
}

@font-face {
    font-family:RNSSanz-Light;
    src: url('./fonts/RNSSanz-Light.otf');
}

@font-face {
    font-family:RNSSanz-Medium;
    src: url('./fonts/RNSSanz-Medium.otf');
}

@font-face {
    font-family:RNSSanz-Normal;
    src: url('./fonts/RNSSanz-Normal.otf');
}

@font-face {
    font-family:RNSSanz-SemiBold;
    src: url('./fonts/RNSSanz-SemiBold.otf');
}


@font-face {
    font-family:futura-bold-font;
    src: url('./fonts/futura-bold-font.ttf');
}

@font-face {
    font-family:futura-bold-italic-font;
    src: url('./fonts/futura-bold-italic-font.ttf');
}

@font-face {
    font-family:futura-book-font;
    src: url('./fonts/futura-book-font.ttf');
}

@font-face {
    font-family:futura-book-italic-font;
    src: url('./fonts/futura-book-italic-font.ttf');
}

@font-face {
    font-family:futura-condensedlight;
    src: url('./fonts/futura-condensedlight.otf');
}

@font-face {
    font-family:futura-extra-black-font;
    src: url('./fonts/futura-extra-black-font.ttf');
}

@font-face {
    font-family:futura-heavy-font;
    src: url('./fonts/futura-heavy-font.ttf');
}

@font-face {
    font-family:futura-heavy-italic-font;
    src: url('./fonts/futura-heavy-italic-font.ttf');
}

@font-face {
    font-family:futura-light-bt;
    src: url('./fonts/futura-light-bt.ttf');
}

@font-face {
    font-family:futura-light-font;
    src: url('./fonts/futura-light-font.ttf');
}

@font-face {
    font-family:futura-light-italic-font;
    src: url('./fonts/futura-light-italic-font.ttf');
}

@font-face {
    font-family:futura-medium-bt;
    src: url('./fonts/futura-medium-bt.ttf');
}

@font-face {
    font-family:futura-medium-condensed-bt;
    src: url('./fonts/futura-medium-condensed-bt.ttf');
}

@font-face {
    font-family:futura-medium-italic-font;
    src: url('./fonts/futura-medium-italic-font.ttf');
}

@font-face {
    font-family:futura-xblk-bt;
    src: url('./fonts/futura-xblk-bt.ttf');
}

@font-face {
    font-family:futura;
    src: url('./fonts/futura.ttf');
}




